<template>
    <v-container>
        <v-flex class="settings-header">
            Einstellungen
        </v-flex>
        <v-layout class="justify-content-center">
            <v-flex lg8 md8 sm10 xs12>
                <v-row align="center" align-content="center" dense justify="start">
                    <v-col cols="6" lg="3" md="4" sm="6" v-bind:key="user.id" v-for="user in localUsers" xl="2">
                        <v-card class="user-card" height="460" min-width="150">
                            <v-img
                                    :src="user.img"
                                    @click="addPicture(user)"
                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)"
                                    height="220px"
                                    position="center center"
                            >
                                <v-row align="end"
                                       class="fill-height ma-1 avatar-image-row"
                                       justify="end">
                                    <v-btn align="flex-end" class="camera-btn" color="white" fab small>
                                        <v-icon color="black">
                                            mdi-camera
                                        </v-icon>
                                    </v-btn>
                                </v-row>

                                <template v-slot:placeholder>
                                    <v-skeleton-loader
                                            class="mx-auto"
                                            max-width="300"
                                            type="card"
                                    ></v-skeleton-loader>
                                </template>
                            </v-img>
                            <v-text-field
                                    class="d-flex justify-end"
                                    hide-details
                                    placeholder="Name"
                                    solo
                                    v-model="user.name"
                            ></v-text-field>
                            <v-card-actions row>
                                <v-container>
                                    <v-radio-group
                                            @change="changeSex(user)"
                                            class="sex-setting-radio"
                                            hide-details
                                            row
                                            v-model="user.sex"
                                    >
                                        <v-radio
                                                label="Girl"
                                                value="female"
                                        ></v-radio>
                                        <v-radio
                                                label="Boy"
                                                value="male"
                                        ></v-radio>
                                    </v-radio-group>
                                <v-radio-group
                                        class="sex-setting-radio"
                                        column
                                        hide-details
                                        v-model="user.interest"
                                >
                                    <template v-slot:label>
                                        <div>Interessiert an</div>
                                    </template>
                                    <v-radio
                                            label="Girl"
                                            value="female"
                                    ></v-radio>
                                    <v-radio
                                            label="Boy"
                                            value="male"
                                    ></v-radio>
                                    <v-radio
                                            label="Egal"
                                            value="all"
                                    ></v-radio>
                                </v-radio-group>
                                  <v-btn @click="removeUser(user)" class="delete-user-btn" fab>
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </v-container>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3" md="4" sm="6" xl="2">
                        <v-card @click="addUser" class="user-card" height="460" min-width="150" >
                            <v-container class="fill-height" fluid>
                                <v-row
                                        align="center"
                                        justify="center"
                                >
                                    <v-col>
                                        <v-icon x-large>mdi-plus</v-icon>
                                        <v-flex>Neuer Spieler</v-flex>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-snackbar
                            top
                            v-model="snackbar"
                    >
                        {{ errorMessage }}

                        <template v-slot:action="{ attrs }">
                            <v-btn
                                    @click="snackbar = false"
                                    color="red"
                                    icon
                                    v-bind="attrs"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-row>
                <v-row>
                    <v-col
                            cols="12"
                            sm="12"
                    >
                        <v-list rounded>
                            <v-subheader>Kategorien:</v-subheader>
                            <v-list-item-group
                                    color="#00aa00"
                                    multiple
                                    v-model="usedCategories"
                            >
                                <v-progress-circular
                                        indeterminate
                                        v-if="loading"
                                ></v-progress-circular>
                                <v-list-item
                                        :key="category.id"
                                        :value="category"
                                        v-for="category in possibleCategories"
                                >

                                    <template v-slot:default="{ active }">
                                        <v-list-item-icon>
                                            <v-icon v-text="category.icon"></v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="category.name"></v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-checkbox
                                                    :input-value="active"
                                                    color="#00aa00 accent-4"
                                            ></v-checkbox>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                            cols="12"
                            sm="12"
                    >
                        <v-list rounded>
                            <v-list-item-group
                                    color="#00aa00"
                                    v-model="usedWildness"
                            >
                                <v-list-item
                                        value="wild"
                                >

                                    <template v-slot:default="{ active }">
                                        <v-list-item-icon>
                                            <v-icon>mdi-thermometer-plus</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Aufgaben werden immer heißer</v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-checkbox
                                                    :input-value="active"
                                                    color="#00aa00 accent-4"
                                            ></v-checkbox>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                                <v-col cols="12" v-if="usedWildness === 'wild'">
                                    <v-subheader class="pl-0">
                                        <div>Hotnessfaktor</div>
                                    </v-subheader>
                                    <v-slider
                                            hint="(Je höher, desto schneller wirds heiß)"
                                            persistent-hint
                                            ticks="always"
                                            tick-size="4"
                                            :thumb-size="18"
                                            max="10"
                                            min="1"
                                            step="1"
                                            thumb-label="always"
                                            v-model="hotnessFactor"
                                    ></v-slider>
                                </v-col>
                                <v-list-item
                                        value="random"
                                >

                                    <template v-slot:default="{ active }">
                                        <v-list-item-icon>
                                            <v-icon>mdi-thermometer-off</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Aufgaben komplett zufällig</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-checkbox
                                                    :input-value="active"
                                                    color="#00aa00 accent-4"
                                            ></v-checkbox>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                </v-row>
                <v-btn @click="start" block dark>
                    Los geht's
                </v-btn>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import SettingsService from "../services/SettingsService";
    import { mapGetters, mapMutations } from 'vuex';
    import ConfigService from "../services/ConfigService";
    export default {
        name: "Settings",
        data: function() {
            return {
                localUsers: [],
                errorMessage: "",
                snackbar: false,
                possibleCategories: [],
                usedCategories: [],
                usedWildness: "wild",
                loading: true,
                hotnessFactor: 5
            }
        },
        async mounted() {
          this.localUsers = this.users;
          this.possibleCategories = await ConfigService.loadCategories();
          this.usedCategories = this.categories;
          if (this.usedCategories.length === 0) {
              this.usedCategories = this.possibleCategories;
          }
          this.usedWildness = this.wildness.usedWildness;
          this.hotnessFactor = this.wildness.hotnessFactor;
          this.loading = false;
        },
        computed: {
          ...mapGetters(['users', 'categories', 'wildness']),
        },
        methods: {
            ...mapMutations(['setUsers', 'setCategories', 'setWildness']),
            addUser: function() {
                const img = SettingsService.getAvatar("female");
                this.localUsers.push({id: this.users.length, name: "", interest: "all", sex: "female", img: img, useCustomImg: false });
            },
            removeUser(user) {
              for (let i = 0; i < this.localUsers.length; i++) {
                  if (user === this.localUsers[i]) {
                      this.localUsers.splice(i, 1);
                  }
              }
            },
            changeSex(user) {
                if (!user.useCustomImg) {
                    const avatar = SettingsService.getAvatar(user.sex);
                    user.img = avatar;
                }
            },
            addPicture: function(user) {
                const setUserImage = function(url) {
                    user.useCustomImg = true;
                    user.img = "data:image;base64," + url;
                };
                const failedUserImage = function(message) {
                    this.snackbar = true;
                    this.errorMessage = message;
                };
                navigator.camera.getPicture(setUserImage, failedUserImage, { quality: 50,
                    destinationType: navigator.camera.DestinationType.DATA_URL,
                    sourceType: navigator.camera.PictureSourceType.CAMERA,
                    correctOrientation: true,
                    encodingType: navigator.camera.EncodingType.JPEG,
                    targetHeight: 2200,
                    targetWidth: 2640
                })
            },
            validateUsers() {
                return this.localUsers.length > 1 && !this.localUsers.some(user => {
                    return user.name === "";
                })
            },
            start() {
                if (this.validateUsers()) {
                    this.setUsers(this.localUsers);
                    this.setCategories(this.usedCategories);
                    this.setWildness({usedWildness: this.usedWildness, hotnessFactor: this.hotnessFactor });
                    localStorage.setItem('users', JSON.stringify(this.localUsers));
                    this.$router.push('game');
                } else {
                    this.errorMessage = "Alleine oder mit namenlosen Mitspielern ist es gar nicht so lustig ;)"
                    this.snackbar = true;
                }
            }
        }
    }
</script>

<style scoped>
    .user-card {

    }
    .justify-content-center {
        justify-content: center;
    }
    .avatar-image-row {
        justify-content: flex-end;
        align-content: flex-end;
        padding-right: 10px;
    }
    .center-image {
        width: 100%;
    }
    .sex-setting-radio {
        margin-top: 0;
    }
    .camera-btn {
        margin-right: -14px;
        margin-bottom: 4px;
    }
    .delete-user-btn {
        margin-right: -17px;
        margin-top: -58px;
        float: right;
    }
    .settings-header {
        display: flex;
        align-items: center;
        text-align: center;
        margin-bottom: 10px;
        margin-top: -10px;
    }

    .settings-header::before,
    .settings-header::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #000;
    }

    .settings-header:not(:empty)::before {
        margin-right: .25em;
    }

    .settings-header:not(:empty)::after {
        margin-left: .25em;
    }

</style>
