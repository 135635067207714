<template>
    <v-container @click="checkInput($event)" full-height class="game-area align-content-center" >
        <v-row class="category-header">
            {{category.name}}
        </v-row>
        <v-row  justify="center" align="center">
            <v-flex class="question-area">
                <v-progress-circular
                        indeterminate
                        v-if="loading"
                ></v-progress-circular>
                {{question}}
            </v-flex>
        </v-row>
        <v-row dense align="center" align-content="center" justify="center">
            <v-col cols="6" md="4" sm="4" lg="3" xl="2" v-bind:key="user.id" v-for="user in selectedUsers">
                <v-card min-width="100">
                    <v-img
                            :src="user.img"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)"
                            height="170px"
                    >
                        <template v-slot:placeholder>
                            <v-skeleton-loader
                                    class="mx-auto"
                                    max-width="200"
                                    type="card"
                            ></v-skeleton-loader>
                        </template>
                        <v-flex class="name-area d-flex justify-end align-self-end">
                            {{ user.name }}
                        </v-flex>
                    </v-img>
                </v-card>
            </v-col>
        </v-row>
        <v-fab-transition>
            <v-btn
                    color="black"
                    dark
                    fixed
                    bottom
                    right
                    fab
                    @click="goToSettings"
            >
                <v-icon>mdi-account-cog</v-icon>
            </v-btn>
        </v-fab-transition>
    </v-container>
</template>

<script>
    import {mapGetters} from "vuex";
    import QuestionService from "../services/QuestionService";
    import {random, template, isEmpty, cloneDeep, minBy, remove, isNil } from "lodash";

    export default {
        name: "Game",
        data() {
            return {
                selectedUsers: [],
                question: "",
                questions: [],
                settingsDialog: false,
                questionStack: [],
                questionIndex: 0,
                category: "",
                wildnessThreshold: 100,
                loading: true,
                currentRound: 0,
                roundStack: []
            }
        },
        async mounted() {
            await this.initialize();
        },
        computed: {
            ...mapGetters(['users', 'categories', 'wildness']),
        },
        methods: {
            async checkInput(event) {
                if (screen.width * 0.25 < event.x) {
                    if (this.questionIndex < this.questionStack.length - 1) {
                        this.questionIndex += 1;
                        this.question = this.questionStack[this.questionIndex].question;
                        this.selectedUsers = this.questionStack[this.questionIndex].selectedUsers;
                        this.category = this.questionStack[this.questionIndex].category;
                    } else {
                        await this.findQuestion();
                    }
                } else {
                    if (this.questionIndex > 0) {
                        this.questionIndex -= 1;
                        this.question = this.questionStack[this.questionIndex].question;
                        this.selectedUsers = this.questionStack[this.questionIndex].selectedUsers;
                        this.category = this.questionStack[this.questionIndex].category;
                    }
                }
            },
            async initialize() {
                this.loading = true;
                const questions = await QuestionService.loadQuestions();
                this.questions = this.filterPossibleQuestions(questions);
                this.currentRound = 0;
                await this.findQuestion();
            },
            async findQuestion() {
                if (isEmpty(this.questions)) {
                    await this.initialize();
                    return;
                }
                this.currentRound++;
                let roundMessageFound = false;
                this.roundStack.forEach((stack, index) => {
                  if (stack.finishRound === this.currentRound && !roundMessageFound) {
                    this.selectedUsers = stack.selectedUsers;
                    this.question =  stack.message;
                    this.roundStack.splice(index, 1);
                    roundMessageFound = true;
                  }
                });
                if (!roundMessageFound) {
                  let wildnessQuestions = this.questions;
                  if (this.wildness.usedWildness === "wild") {
                      const minWildness = minBy(this.questions, 'wildness').wildness;
                      if (minWildness > this.wildnessThreshold) {
                          this.wildnessThreshold = minWildness + 1;
                      }
                      wildnessQuestions = this.questions.filter(question => {
                          return question.wildness < this.wildnessThreshold
                      });
                  }
                  const questionIndex = random(0, wildnessQuestions.length - 1);
                  const question = wildnessQuestions[questionIndex];
                  this.wildnessThreshold += Math.round(question.wildness / (11 - this.wildness.hotnessFactor));
                  this.category = this.categories.find(category => category.id === question.category);
                  const questionTemplate = template(question.template);
                  let roundTemplate;
                  if (!isNil(question.round)) {
                    roundTemplate = template(question.round.template);
                  }
                  let addedUser = { male: [], female: [], user: [] };
                  if (question.all === "male") {
                      this.selectedUsers = this.users.filter(user => user.sex === "male");
                  } else if (question.all === "female") {
                      this.selectedUsers = this.users.filter(user => user.sex === "female");
                  } else if (question.male > 0 || question.female > 0 || question.user > 0) {
                      addedUser = this.findUsers(question, 0);
                      if (isEmpty(addedUser)) {
                          this.questions.splice(questionIndex, 1);
                          await this.findQuestion();
                          return;
                      }
                      this.selectedUsers = this.concatUser(addedUser);
                  } else {
                      this.selectedUsers = this.users;
                  }
                  const templateOptions = {schluck: random(2, 5), kleid: random(1, 3)};
                  const roundOptions = {};
                  addedUser.female.forEach((user, i) => {
                      templateOptions["female" + (i + 1)] = user.name;
                      roundOptions["female" + (i + 1)] = user.name;
                  });
                  addedUser.male.forEach((user, i) => {
                      templateOptions["male" + (i + 1)] = user.name;
                      roundOptions["male" + (i + 1)] = user.name;
                  });
                  addedUser.user.forEach((user, i) => {
                      templateOptions["user" + (i + 1)] = user.name;
                      roundOptions["user" + (i + 1)] = user.name;
                      if (!isNil(question.salutation) && !isNil(question.salutation["user" + (i + 1)])) {
                          question.salutation["user" + (i + 1)].forEach((salutationUser, j) => {
                              templateOptions["user" + (i + 1) + "_salutation" + (j + 1)] =
                                user.sex === "female" ? salutationUser.female : salutationUser.male;
                              roundOptions["user" + (i + 1) + "_salutation" + (j + 1)] =
                                user.sex === "female" ? salutationUser.female : salutationUser.male;
                          });
                      }
                  });
                  if (!isNil(question.round)) {
                    this.roundStack.push({ finishRound: this.currentRound + question.round.rounds, message: roundTemplate(roundOptions), selectedUsers: this.selectedUsers});
                  }
                  this.question = questionTemplate(templateOptions);
                  remove(this.questions, questionIterate => {
                     return questionIterate.id === question.id
                  });
                  this.questionStack.push({ question: this.question, selectedUsers: this.selectedUsers, category: this.category });
                  this.questionIndex += 1;
              }
                this.loading = false;
            },
            concatUser(addedUser) {
                return [...addedUser.male, ...addedUser.female, ...addedUser.user];
            },
            findUsers(question) {
                let possibleUserCombinations = this.findUserMatch([], question)
                return possibleUserCombinations[random(0, possibleUserCombinations.length - 1)];
            },
            findUserMatch(addedUser, question) {
                const possibleUserCombination = []
                if (question.female > 0) {
                    this.users.filter(user => user.sex === "female").forEach(user => {
                        possibleUserCombination.push(...this.findRecursiveUser([user], [], {
                            female: [user],
                            male: [],
                            user: []
                        }, question));
                    });
                } else if (question.male > 0) {
                    this.users.filter(user => user.sex === "male").forEach(user => {
                        possibleUserCombination.push(...this.findRecursiveUser([user], [], {
                            female: [],
                            male: [user],
                            user: []
                        }, question));
                    });
                } else if (question.user > 0) {
                    this.users.forEach(user => {
                        possibleUserCombination.push(...this.findRecursiveUser([user], [], {
                            female: [],
                            male: [],
                            user: [user]
                        }, question));
                    });
                }
                return possibleUserCombination;
            },
            findRecursiveUser(checkedUser, possibleUsers, currentObject, question) {
                if (currentObject.user.length === question.user && currentObject.female.length === question.female && currentObject.male.length === question.male) {
                    possibleUsers.push(cloneDeep(currentObject));
                    return possibleUsers;
                }
                if (checkedUser.length === this.users.length) {
                    return possibleUsers;
                }
                if (currentObject.female.length < question.female) {
                    this.users.filter(user => user.sex === "female" && !checkedUser.some(checkUser => user.id === checkUser.id)).forEach(user => {
                        if (question.interaction) {
                            if (this.checkForInteractionError(user, currentObject)) {
                                const newObject = cloneDeep(currentObject);
                                newObject.female.push(user);
                                checkedUser.push(user);
                                possibleUsers = this.findRecursiveUser(checkedUser, possibleUsers, newObject, question);
                            } else {
                                const newObject = cloneDeep(currentObject);
                                newObject.female.push(user);
                                checkedUser.push(user);
                                possibleUsers = this.findRecursiveUser(checkedUser, possibleUsers, newObject, question);
                            }
                        }
                    })
                } else if (currentObject.male.length < question.male) {
                    this.users.filter(user => user.sex === "male" && !checkedUser.some(checkUser => user.id === checkUser.id)).forEach(user => {
                        if (question.interaction) {
                            if (this.checkForInteractionError(user, currentObject)) {
                                const newObject = cloneDeep(currentObject);
                                newObject.male.push(user);
                                checkedUser.push(user);
                                possibleUsers = this.findRecursiveUser(checkedUser, possibleUsers, newObject, question);
                            }
                        } else {
                            const newObject = cloneDeep(currentObject);
                            newObject.male.push(user);
                            checkedUser.push(user);
                            possibleUsers = this.findRecursiveUser(checkedUser, possibleUsers, newObject, question);
                        }
                    })
                } else if (currentObject.user.length < question.user) {
                    this.users.filter(user => !checkedUser.some(checkUser => user.id === checkUser.id)).forEach(user => {
                        if (question.interaction) {
                            if (this.checkForInteractionError(user, currentObject)) {
                                const newObject = cloneDeep(currentObject);
                                newObject.user.push(user);
                                checkedUser.push(user);
                                possibleUsers = this.findRecursiveUser(checkedUser, possibleUsers, newObject, question);
                            }
                        } else {
                            const newObject = cloneDeep(currentObject);
                            newObject.user.push(user);
                            checkedUser.push(user);
                            possibleUsers = this.findRecursiveUser(checkedUser, possibleUsers, newObject, question);
                        }
                    })
                }
                return possibleUsers;
            },
            checkForInteractionError(user, currentObject) {
                let alright = true;
                if (!isEmpty(currentObject.female) && !currentObject.female.every(female => {
                    if (user.interest !== "female" && user.interest !== "all") {
                        return false;
                    }
                    return !(female.interest !== "all" && female.interest !== user.sex);

                })) {
                    alright = false;
                }
                if (!isEmpty(currentObject.male) && !currentObject.male.every(male => {
                        if (user.interest !== "male" && user.interest !== "all") {
                            return false;
                        }
                        return !(male.interest !== "all" && male.interest !== user.sex);

                    })) {
                    alright = false;
                }
                if (!isEmpty(currentObject.user) && !currentObject.user.every(checkUser => {
                        if (user.interest !== checkUser.sex && user.interest !== "all") {
                            return false;
                        }
                        return !(checkUser.interest !== user.sex && checkUser.interest !== "all");
                    })) {
                    alright = false;
                }
                return alright;
            },
            filterPossibleQuestions(questions) {
                const numberMale = this.users.filter(user => user.sex === "male").length;
                const numberFemale = this.users.filter(user => user.sex === "female").length;
                const numberFemaleInteraction = this.users.filter(user => user.interest === "female" || user.interest === "all").length;
                const numberMaleInteraction = this.users.filter(user => user.interest === "male" || user.interest === "all").length;
                return questions.filter(question => {
                    if (!this.categories.find(category => category.id === question.category)) {
                        return false;
                    }
                    if (numberFemale < question.female) {
                        return false;
                    }
                    if (numberMale < question.male) {
                        return false;
                    }
                    if (this.users.length < question.user + question.male + question.female) {
                        return false;
                    }
                    if (question.interaction) {
                        if (numberFemaleInteraction < question.female) {
                            return false;
                        }
                        if (numberMaleInteraction < question.male) {
                            return false;
                        }

                    }
                    return true;
                });
            },
            goToSettings() {
                this.$router.push('home');
            }
        }
    }
</script>

<style>
    .v-image .v-responsive__content {
        display: flex;
    }
</style>

<style scoped>
    .game-area {
        min-height: 100vh;
    }

    .name-area {
        font-size: 30px;
        font-weight: bolder;
        font-family: Helvetica, Arial;
        font-style: revert;
        text-transform: uppercase;
        align-self: flex-end;
    }

    .question-area {
        font-size: 40px;
        margin: 20px;
        font-weight: bolder;
        color: black;
    }
    .category-header {
        display: flex;
        align-items: center;
        text-align: center;
    }

    .category-header::before,
    .category-header::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #000;
    }

    .category-header:not(:empty)::before {
        margin-right: .25em;
    }

    .category-header:not(:empty)::after {
        margin-left: .25em;
    }
</style>
