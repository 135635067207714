<template>
    <v-container @click="go($event)" fluid class="align-content-center start-screen fill-height">
            <v-row justify="center" align="center" >
                <v-col>
                    <v-img src="../assets/group-play.svg"></v-img>
                </v-col>
            </v-row>
            <v-row justify="center" align="center" >
                <v-col>
                    <v-flex class="mx-auto click-to-start">
                        Klicke zum Starten
                    </v-flex>
                </v-col>
            </v-row>
            <v-row v-if="canDownloadAPK">
                <v-col>
                    <v-btn block @click="startDownload = true">
                        App für Android downloaden
                    </v-btn>
                    <iframe v-if="startDownload" :src="apkURL" style="display: none"></iframe>
                </v-col>
            </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "Start",
        data() {
            return {
                apkURL: "https://group-play.florian-prey.at/groupplay.apk",
                startDownload: false,
                canDownloadAPK: false,
            }
        },
        created() {
            document.addEventListener("deviceready", this.deviceReady, false);
        },
        methods: {
            deviceReady() {
                const userAgent = navigator.userAgent.toLowerCase();
                this.canDownloadAPK = window.device.platform === "browser" && userAgent.includes("android");
            },
            go($event) {
                if ($event.target.className !== "v-btn__content") {
                    this.$router.push('home');
                }
            }
        }
    }
</script>

<style scoped>
    .click-to-start {
        font-family: Helvetica, "Arial Black", Arial;
        font-size: 35px;
        color: black;
        font-weight: bold;
    }
    .start-screen {
        min-height: 100vh;
    }
</style>