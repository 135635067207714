import Vuex from 'vuex'
import Vue from "vue";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        users: [],
        categories: [],
        wildness: { usedWildness: "wild", hotnessFactor: 5 }
    },
    getters: {
        users(state) {
          return state.users;
        },
        categories(state) {
          return state.categories;
        },
        wildness(state) {
            return state.wildness;
        }
    },
    mutations: {
        setUsers (state, users) {
            state.users = users;
        },
        setCategories (state, categories) {
            state.categories = categories;
        },
        setWildness (state, wildness) {
            state.wildness = wildness;
        }
    }
})
