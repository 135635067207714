import config from "../database/configs.json"
import axios from "axios";
const database = "https://proxy.florian-prey.at/proxy.php?url=https://group-play.florian-prey.at/database/";
import { isNil } from "lodash";
let configData = null;

export default class ConfigService {

    static async loadCategories() {
        if (isNil(configData)) {
            const configDataResponse = await axios.get(database + "configs.json");
            if (!isNil(configDataResponse.data)) {
                configData = configDataResponse.data;
            } else {
                configData = config;
            }
            console.log(configData)
        }
        return configData.categories;
    }

}
