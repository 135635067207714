<template>
  <v-container fluid fill-height>
    <Settings></Settings>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Settings from "../components/Settings";

export default {
  name: 'Home',
  components: {
    Settings
  }
}
</script>

<style scoped>
</style>
