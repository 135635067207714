import questions from "../database/questions.json"
import axios from "axios";
const database = "https://proxy.florian-prey.at/proxy.php?url=https://group-play.florian-prey.at/database/";
import { isNil } from "lodash";

export default class QuestionService {

    static async loadQuestions() {
        const questionData = await axios.get(database + "questions.json");
        if (!isNil(questionData.data)) {
            return questionData.data;
        }
        return questions;
    }

}
