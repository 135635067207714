import { random } from 'lodash';

export default class SettingsService {

    static getAvatar(sex) {
        const o = this._avatarOptions(sex);
        const baseUrl = "https://avataaars.io/?avatarStyle=Transparent&"
        return `${baseUrl}accessoriesType=${
            o.accessoriesTypeOptions[
                random(o.accessoriesTypeOptions.length - 1)
                ]
        }&avatarStyle=Circle&clotheColor=${
            o.clotheColorOptions[random(o.clotheColorOptions.length - 1)]
        }&clotheType=${
            o.clotheTypeOptions[random(o.clotheTypeOptions.length - 1)]
        }&eyeType=${
            o.eyeTypeOptions[random(o.eyeTypeOptions.length - 1)]
        }&eyebrowType=${
            o.eyebrowTypeOptions[random(o.eyebrowTypeOptions.length - 1)]
        }&facialHairColor=${
            o.facialHairColorOptions[
                random(o.facialHairColorOptions.length - 1)
                ]
        }&facialHairType=${
            o.facialHairTypeOptions[
                random(o.facialHairTypeOptions.length - 1)
                ]
        }&hairColor=${
            o.hairColorTypes[random(o.hairColorTypes.length - 1)]
        }&hatColor=${
            o.hatColorOptions[random(o.hatColorOptions.length - 1)]
        }&mouthType=${
            o.mouthTypeOptions[random(o.mouthTypeOptions.length - 1)]
        }&skinColor=${
            o.skinColorOptions[random(o.skinColorOptions.length - 1)]
        }&topType=${
            o.topTypeOptions[random(o.topTypeOptions.length - 1)]
        }`;
    }

    static _avatarOptions(sex) {
        let topTypeOptionsMale = [
            "LongHairBun",
            "LongHairDreads",
            "LongHairFroBand",
            "ShortHairDreads01",
            "ShortHairDreads02",
            "ShortHairFrizzle",
            "ShortHairShaggyMullet",
            "ShortHairShortCurly",
            "ShortHairShortFlat",
            "ShortHairShortRound",
            "ShortHairShortWaved",
            "ShortHairSides",
            "ShortHairTheCaesar",
            "ShortHairTheCaesarSidePart"
        ];

        let topTypeOptionsFemale = [
            "LongHairBigHair",
            "LongHairBob",
            "LongHairBun",
            "LongHairCurly",
            "LongHairCurvy",
            "LongHairDreads",
            "LongHairFrida",
            "LongHairFro",
            "LongHairFroBand",
            "LongHairNotTooLong",
            "LongHairShavedSides",
            "LongHairMiaWallace",
            "LongHairStraight",
            "LongHairStraight2",
            "LongHairStraightStrand",
        ];

        let accessoriesTypeOptions = [
            "Blank",
            "Kurt",
            "Prescription01",
            "Prescription02",
            "Round",
            "Sunglasses",
            "Wayfarers"
        ];

        let facialHairTypeOptionsMale = [
            "Blank",
            "BeardMedium",
            "BeardLight",
            "BeardMagestic",
            "MoustacheFancy",
            "MoustacheMagnum"
        ];
        let facialHairTypeOptionsFemale = [
            "Blank"
        ];

        let facialHairColorOptions = [
            "Auburn",
            "Black",
            "Blonde",
            "BlondeGolden",
            "Brown",
            "BrownDark",
            "Platinum",
            "Red"
        ];

        let clotheTypeOptions = [
            "BlazerShirt",
            "BlazerSweater",
            "CollarSweater",
            "GraphicShirt",
            "Hoodie",
            "Overall",
            "ShirtCrewNeck",
            "ShirtScoopNeck",
            "ShirtVNeck"
        ];

        let eyeTypeOptions = [
            "Close",
            "Cry",
            "Default",
            "Dizzy",
            "EyeRoll",
            "Happy",
            "Hearts",
            "Side",
            "Squint",
            "Surprised",
            "Wink",
            "WinkWacky"
        ];

        let eyebrowTypeOptions = [
            "Angry",
            "AngryNatural",
            "Default",
            "DefaultNatural",
            "FlatNatural",
            "RaisedExcited",
            "RaisedExcitedNatural",
            "SadConcerned",
            "SadConcernedNatural",
            "UnibrowNatural",
            "UpDown",
            "UpDownNatural"
        ];

        let mouthTypeOptions = [
            "Concerned",
            "Default",
            "Disbelief",
            "Eating",
            "Grimace",
            "Sad",
            "ScreamOpen",
            "Serious",
            "Smile",
            "Tongue",
            "Twinkle",
            "Vomit"
        ];

        let skinColorOptions = [
            "Tanned",
            "Yellow",
            "Pale",
            "Light",
            "Brown",
            "DarkBrown",
            "Black"
        ];

        let hairColorTypes = [
            "Auburn",
            "Black",
            "Blonde",
            "BlondeGolden",
            "Brown",
            "BrownDark",
            "PastelPink",
            "Platinum",
            "Red",
            "SilverGray"
        ];

        let hatColorOptions = [
            "Black",
            "Blue01",
            "Blue02",
            "Blue03",
            "Gray01",
            "Gray02",
            "Heather",
            "PastelBlue",
            "PastelGreen",
            "PastelOrange",
            "PastelRed",
            "PastelYellow",
            "Pink",
            "Red",
            "White"
        ];

        let clotheColorOptions = [
            "Black",
            "Blue01",
            "Blue02",
            "Blue03",
            "Gray01",
            "Gray02",
            "Heather",
            "PastelBlue",
            "PastelGreen",
            "PastelOrange",
            "PastelRed",
            "PastelYellow",
            "Pink",
            "Red",
            "White"
        ];

        return {
            topTypeOptions: sex === "male" ? topTypeOptionsMale : topTypeOptionsFemale,
            accessoriesTypeOptions,
            facialHairColorOptions,
            facialHairTypeOptions: sex === "male" ? facialHairTypeOptionsMale : facialHairTypeOptionsFemale,
            clotheColorOptions,
            clotheTypeOptions,
            eyeTypeOptions,
            eyebrowTypeOptions,
            mouthTypeOptions,
            skinColorOptions,
            hairColorTypes,
            hatColorOptions
        }

    }

}